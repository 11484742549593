<template>
  <div class="split-button">
    <Button
      :variant="variant"
      :loading="loading"
      :disabled="disabled"
      @click="handlePrimaryClick"
    >
      <slot />
    </Button>
    <Button
      v-if="!loading"
      :variant="variant"
      :disabled="disabled"
      :icon="buttonIcon"
      icon-only
      @click="handleAuxiliaryClick"
    />
    <div
      v-if="actions.length && isDropdownOpen"
      class="split-button__dropdown"
    >
      <div
        v-for="action in actions"
        :key="action.id"
        class="split-button__dropdown-item"
        @click="handleAuxiliaryClick(action.id)"
      >
        {{ action.label }}
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { onClickOutside } from '@vueuse/core';
import Button from '@/components/common/Button';

export default {
  components: {
    Button,
  },
  props: {
    variant: {
      type: String,
      default: 'secondary',
    },
    icon: {
      type: String,
      default: '',
    },
    actions: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    click: {
    },
    auxiliaryClick: {
    },
    handleAction: {
      type: String,
    },
  },
  setup(props, { emit }) {
    const rootElement = ref(null);

    const isDropdownOpen = ref(false);
    const buttonIcon = computed(() => (props.actions.length ? 'chevron-down' : props.icon));

    const handlePrimaryClick = () => {
      emit('click');
    };
    const handleAuxiliaryClick = (actionId) => {
      if (!props.actions.length) {
        emit('auxiliaryClick');
        return;
      }

      if (actionId) {
        emit('handleAction', actionId);
        isDropdownOpen.value = false;
        return;
      }

      isDropdownOpen.value = true;
    };

    onClickOutside(rootElement, () => {
      isDropdownOpen.value = false;
    });

    return {
      rootElement,
      isDropdownOpen,
      buttonIcon,
      handlePrimaryClick,
      handleAuxiliaryClick,
    };
  },
};
</script>

<style lang="scss">
.split-button {
  position: relative;
  display: flex;
  gap: 1px;
  stroke: #000;
}

.split-button__dropdown {
  background-color: #fff;
  position: absolute;
  top: calc(100% + 4px);
  right: 50%;
  transform: translateX(50%);
  z-index: 1000;
  padding: 4px 0;
  border: 1px solid #DDDDDD;
  box-shadow: 0px 8px 12px -6px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

.split-button__dropdown-item {
  background-color: #fff;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  padding: 8px;
  color: #191414;
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    background-color: #FAFAFA;
  }
}
</style>
