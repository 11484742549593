<template>
  <div class="footer">
    <RowsPerPageDrodown />
    <Pagination />
  </div>
</template>

<script>
import RowsPerPageDrodown from './RowsPerPageDropdown';
import Pagination from './Pagination';

export default {
  components: {
    Pagination,
    RowsPerPageDrodown,
  },
};
</script>

<style lang="scss" scoped>
.footer {
  flex: 0;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem map-get($spacers, 'xl');
  border-top: 1px solid #ddd;
  height: 57px;
}
</style>
