<template>
  <header
    class="header"
    :class="{
      'filters-inactive': !isEventsFiltersActive,
    }"
  >
    <div class="header-top">
      <div class="header__title">
        <Heading>
          {{ selectionLabel }}
        </Heading>
        <SubscriptionButton
          v-if="!eventListLoading && selectionCovered"
        />
      </div>

      <div class="header__content">
        <CreateEventControl
          v-if="createEventActive"
        />
        <div
          v-if="createEventActive"
          class="divider"
        />
        <SearchField />
        <!-- <RowSpacingFilter /> -->
        <Button
          icon="three-columns"
          @click="setIsEventsSidebarActive"
        >
          Edit Columns
        </Button>
        <Button
          icon="filter-funnel"
          @click="setIsEventsFiltersActive"
        >
          {{ isEventsFiltersActive ? 'Hide filters' : 'Show filters' }}
        </Button>
      </div>
    </div>
    <div
      v-if="isEventsFiltersActive"
      class="header-bottom"
    >
      <FiltersMain />
    </div>
  </header>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { getSuperAdminData } from '@/services/helpers/super-admin';
import { useSelectionLabel } from '@/composables';
import Heading from '@/components/common/Heading';
import Button from '@/components/common/Button';
import SearchField from './SearchField';
// import RowSpacingFilter from './RowSpacingFilter';
import FiltersMain from './FiltersMain';
import SubscriptionButton from './SubscriptionButton';
import CreateEventControl from './CreateEventControl';

export default {
  components: {
    Heading,
    SearchField,
    // RowSpacingFilter,
    FiltersMain,
    SubscriptionButton,
    CreateEventControl,
    Button,
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const {
      label: selectionLabel,
      covered: selectionCovered,
    } = useSelectionLabel({
      initialLabel: 'All events',
    });
    const { isSuperAdmin } = getSuperAdminData();
    const createEventActive = computed(() => isSuperAdmin);
    const routeName = computed(() => route?.name || '');
    const routeTitle = computed(() => route?.meta?.title || 'Untitled');
    const title = computed(() => (routeName.value === 'events' ? selectionLabel.value : routeTitle.value));
    const hasDpsTraderRole = computed(() => store.getters.isDpsTraderRole);
    const eventListLoading = computed(() => store.getters.eventListLoading);
    const isEventsFiltersActive = computed(() => store.getters.isEventsFiltersActive);

    const setIsEventsSidebarActive = () => {
      store.dispatch('setIsEventsSidebarActive', !store.getters.isEventsSidebarActive);
    };
    const setIsEventsFiltersActive = () => {
      store.dispatch('setIsEventsFiltersActive', !store.getters.isEventsFiltersActive);
    };

    return {
      title,
      isSuperAdmin,
      selectionLabel,
      selectionCovered,
      hasDpsTraderRole,
      eventListLoading,
      setIsEventsSidebarActive,
      isEventsFiltersActive,
      setIsEventsFiltersActive,
      createEventActive,
    };
  },
};
</script>

<style lang="scss">
.header {
  .header-top {
    display:  flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: map-get($spacers, 'm');
  }
  width: 100%;
  height: $headerFiltersHeight;

  &.filters-inactive {
    height: $headerWithoutFiltersHeight;
    .header-top {
      margin-bottom: 0;
    }
  }
  background-color: $eventsHeaderBackgroundColor;
  padding: map-get($spacers, 'l');
  border-radius: 10em;

  .header__title {
    display: flex;
    align-items: center;
    gap: 8px;
    max-width: 60%;

    h4 {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .header__content {
    display: flex;
    align-items: center;
    gap: 4px;
  }
  .divider {
    width: 1px;
    height: 32px;
    background-color: $gray400;
  }
}
</style>
