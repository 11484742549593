<template>
  <div
    ref="rootElement"
    :class="[
      'search-select',
      { 'search-select--is-disabled': isDisabled },
      { 'search-select--is-error': error },
      { 'search-select--is-toggled': isMenuOpen },
    ]"
  >
    <div
      class="search-select__toggle-field"
      @click="toggleMenu"
    >
      <span
        v-if="selectedLabel"
        class="search-select__toggle-label"
      >
        {{ selectedLabel }}
      </span>
      <span
        v-else
        class="search-select__toggle-placeholder"
      >
        {{ placeholder }}
      </span>
      <Icon name="chevron-selector" />
    </div>
    <div
      v-if="!isDisabled && isMenuOpen"
      class="search-select__menu"
    >
      <div class="search-select__menu-control">
        <TextInput
          :model-value="searchQuery"
          @update:model-value="updateSearchQuery"
          :placeholder="searchPlaceholder"
          small
        />
      </div>
      <div
        v-if="isLoading"
        class="search-select__menu-loader"
      >
        <Spinner
          small
        />
      </div>
      <div
        v-else-if="!filteredOptions.length"
        class="search-select__menu-item"
      >
        No results for this query.
      </div>
      <div
        v-for="option in filteredOptions"
        :key="option.value"
        class="search-select__menu-item"
        @click="updateModelValue(option)"
      >
        <span class="search-select__menu-label">
          {{ option.label }}
        </span>
        <Icon
          v-if="modelValue?.value === option.value"
          name="check"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import { ref, computed } from 'vue';
import { onClickOutside } from '@vueuse/core';
import Icon from '@/components/common/Icon';
import Spinner from '@/components/common/Spinner';
import TextInput from '@/components/common/TextInput';

export default {
  components: {
    Icon,
    Spinner,
    TextInput,
  },
  props: {
    modelValue: {
      type: Object,
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: '',
    },
    searchPlaceholder: {
      type: String,
      default: 'Search...',
    },
    error: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    debounceInMs: {
      type: Number,
      default: 250,
    },
  },
  emits: {
    'update:modelValue': {
      type: [String, Number],
    },
    'update:options': {
    },
  },
  setup(props, { emit }) {
    const rootElement = ref(null);
    const isMenuOpen = ref(false);
    const searchQuery = ref('');

    const selectedLabel = computed(() => {
      if (!props.modelValue) return '';
      return props.modelValue?.label || 'N/A';
    });
    const filteredOptions = computed(() => (props.isLoading ? [] : props.options));

    const toggleMenu = () => {
      if (props.isDisabled) return;
      isMenuOpen.value = !isMenuOpen.value;
      if (isMenuOpen.value) emit('update:options', '');
    };
    const closeMenu = () => {
      searchQuery.value = '';
      isMenuOpen.value = false;
    };
    const updateModelValue = (newModelValue) => {
      emit('update:modelValue', newModelValue);
      closeMenu();
    };
    const updateOptions = debounce((newSearchQuery) => {
      emit('update:options', newSearchQuery);
    }, props.debounceInMs);
    const updateSearchQuery = (newSearchQuery) => {
      searchQuery.value = newSearchQuery;
      updateOptions(newSearchQuery);
    };

    onClickOutside(rootElement, closeMenu);

    return {
      rootElement,
      isMenuOpen,
      searchQuery,
      selectedLabel,
      filteredOptions,
      toggleMenu,
      closeMenu,
      updateModelValue,
      updateSearchQuery,
    };
  },
};
</script>

<style lang="scss">
.search-select {
  position: relative;
  height: 100%;

  &--is-disabled {
    .search-select__toggle-field {
      opacity: 0.75;
      cursor: not-allowed;
    }
  }

  &--is-toggled {
    .search-select__toggle-field {
      outline: 1px solid #a2bfff;
    }
  }

  &--is-error {
    .search-select__toggle-field {
      outline: 1px solid red;
    }
  }

  &__toggle-field {
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: $rowsPerPageDropdownButtonBorder;
    border-radius: $rowsPerPageDropdownButtonBorderRadius;
    cursor: pointer;
    transition: $rowsPerPageDropdownButtonTransition;
    user-select: none;
    stroke: $black;
    background: #fff;
    padding: 0 8px;
    height: 32px;
    min-height: 32px;
    max-height: 32px;
    white-space: nowrap;
    overflow: hidden;

    .icon {
      flex-shrink: 0;
    }
  }

  &__toggle-label {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__toggle-placeholder {
    color: #CDCDCD;
  }

  &__menu {
    position: absolute;
    background-color: $rowsPerPageDropdownContentBackgroundColor;
    box-shadow: $rowsPerPageDropdownContentBoxShadow;
    border-radius: $rowsPerPageDropdownContentBorderRadius;
    margin-bottom: $rowsPerPageDropdownContentMarginBottom;
    border: $rowsPerPageDropdownContentBorder;
    min-width: 100%;
    width: 100%;
    user-select: none;
    z-index: $dropdownMenuZIndex;
    max-height: 300px;
    overflow: auto;

    &-control {
      position: sticky;
      top: 0;
      padding: 8px;
      border-bottom: $rowsPerPageDropdownContentBorder;
      background: #fff;
    }

    &-loader {
      padding: 16px 0;
    }

    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px;
      gap: 8px;
      background: var(--neutral-bg-default-resting, #FFF);
      overflow: hidden;
      color: var(--neutral-text-default-resting, #191414);
      text-overflow: ellipsis;
      font-family: Rubik;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      white-space: nowrap;
      width: 100%;
      flex: 1;
      cursor: pointer;
      overflow: hidden;

      &:hover,
      &:focus {
        background-color: $rowsPerPageDropdownItemHoverBackgroundColor;
      }
    }

    &-label {
      overflow: hidden;
      text-overflow: ellipsis;

    }

    .icon {
      flex-shrink: 0;
      stroke: #000;
    }
  }
}
</style>
