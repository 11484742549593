<template>
  <div class="percentage-chart">
    <svg
      :class="[
        'chart-container',
        {'high-sim': similarity >= 0.75},
        {'med-sim': similarity >= 0.33 && similarity < 0.75},
        {'low-sim': similarity < 0.33},
      ]"
    >
      <circle
        cx="30"
        cy="30"
        r="25"
        class="back"
        fill="none"
      />
      <circle
        cx="30"
        cy="30"
        r="25"
        class="front"
        fill="none"
        :style="{
          strokeDasharray: `calc(${157 * similarity}), ${157}`, // 125.6 = 2 * PI * Radius, r is 20
        }"
      />

      <g class="text">
        <text
          x="30"
          y="30"
          alignment-baseline="central"
          text-anchor="middle"
          id="percentage"
        >
          {{ parseInt(similarity * 100) }}%
        </text>
      </g>
    </svg>
  </div>
</template>

<script>

export default {
  components: {},
  props: {
    similarity: {
      type: Number,
      required: true,
    },
  },
  setup() {
    return {
    };
  },
};
</script>

<style lang="scss">
.percentage-chart {
  display: flex;
  justify-content: center;
  min-height: 60px;
  height: 60px;
  width: 60px;

  .chart-container {
    $stroke-width: 5;

    .back {
      stroke-width:  $stroke-width;
    }

    .front {
      stroke-width: $stroke-width;
      stroke-linecap: round;
      // Without transform and transform origin, the stroke won't start in the top
      transform: rotate(-90deg);
      transform-origin: center;
      animation: fill 1s reverse;
    }

    &.low-sim {
      .back {
        stroke: #FFD5D5;
      }

      .front {
        stroke: #FF2E2D;
      }
    }

    &.med-sim {
      .back {
        stroke: #FFF3D6;
      }

      .front {
        stroke: #FFC531;
      }
    }

    &.high-sim {
      .back {
        stroke: #CCF2DD;
      }

      .front {
        stroke: #00BC57;
      }
    }

    .text {
      font-size: 12px;
      // darken is a SCSS convenient function to make a color darker
      fill: #191414;
      opacity: 0;
      animation: display 1.5s forwards;
    }

    @keyframes fill {
        // 1000000 is just a really big number to make sure there will be no "dash" inside the stroke
        to { stroke-dasharray: 0,1000000000; }
    }

    @keyframes display {
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

  }
}
</style>
