<template>
  <div class="create-event-control">
    <Button
      @click="showModal"
      :icon="'plus'"
    >
      Create event
    </Button>
    <teleport to="body">
      <CreateEventModal
        v-if="isModalVisible"
        @on-close="hideModal"
        @on-submit="onSubmit"
      />
    </teleport>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useStore } from 'vuex';
import Button from '@/components/common/Button';
import CreateEventModal from './CreateEventModal';

export default {
  components: {
    Button,
    CreateEventModal,
  },
  setup() {
    const store = useStore();

    const isModalVisible = ref(false);

    const showModal = () => {
      isModalVisible.value = true;
    };
    const hideModal = () => {
      isModalVisible.value = false;
    };
    const onSubmit = () => {
      hideModal();
      store.dispatch('loadEvents');
    };

    return {
      isModalVisible,
      showModal,
      hideModal,
      onSubmit,
    };
  },
};
</script>

<style lang="scss">
.create-event-control {
}
</style>
