<template>
  <LitepieDatepicker
    ref="rootElement"
    :model-value="parsedModelValue"
    @update:modelValue="updateModelValue"
    :formatter="{ date: 'DD.MM.YYYY.', month: 'MMM' }"
    separator=" - "
    :as-single="true"
    :use-range="range"
    readonly
    v-slot="{ value, placeholder }"
  >
    <div
      ref="datePicker"
      :class="['date-picker', (isPickerActive || (value && parsedModelValue)) && 'active', error && 'error']"
      @click="datePickerActivated"
      @mouseover="datePickerHovered(true)"
      @mouseleave="datePickerHovered(false)"
    >
      <div>
        <span
          v-if="!value || !parsedModelValue"
          class="date-picker-placeholder"
        >
          <span v-if="hidePlaceholder">
            Date
          </span>
          <span v-else>
            {{ placeholder }}
          </span>
        </span>
        <span v-else-if="hidePlaceholder">
          {{ `${displayValue(value)}` }}
        </span>
        <span v-else>
          {{ `${placeholder}: ${displayValue(value)}` }}
        </span>
      </div>
      <Icon
        v-if="!isClearIconActive"
        @click.stop="datePicker.click()"
        class="chevron-icon"
        :name="isPickerActive ? 'chevron-up' : 'chevron-down'"
      />
      <Icon
        v-else
        @click.stop="updateModelValue(null)"
        class="clear-icon"
        :name="'close'"
      />
    </div>
  </LitepieDatepicker>
</template>

<script>
import { computed, ref } from 'vue';
import { split, slice } from 'lodash';
import { parse, format } from 'date-fns';
import { onClickOutside } from '@vueuse/core';
import LitepieDatepicker from 'litepie-datepicker';
import Icon from '@/components/common/Icon';

export default {
  components: {
    LitepieDatepicker,
    Icon,
  },
  props: {
    modelValue: {
      type: [Date, String, Array],
      default: () => '',
    },
    range: {
      type: Boolean,
      default: () => false,
    },
    error: {
      type: String,
      default: '',
    },
    hidePlaceholder: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const isPickerActive = ref(false);
    const isClearIconActive = ref(false);
    const rootElement = ref(null);
    const datePicker = ref(null);
    const parsedModelValue = computed(() => {
      if (props.modelValue) {
        if (props.range) {
          return [
            format(parse(props.modelValue[0], 'yyyy-LL-dd', new Date()), 'dd.LL.yyyy.'),
            format(parse(props.modelValue[1], 'yyyy-LL-dd', new Date()), 'dd.LL.yyyy.'),
          ];
        }
        return format(parse(props.modelValue, 'yyyy-LL-dd', new Date()), 'dd.LL.yyyy.');
      }
      return '';
    });
    const displayValue = (value) => {
      if (!props.range) return value;
      const rangeValues = split(value, ' - ');
      if (rangeValues[0] === rangeValues[1]) return rangeValues[0];
      const sliceIndex = 6;
      return `${slice(rangeValues[0], 0, sliceIndex).join('')} - ${slice(rangeValues[1], 0, sliceIndex).join('')}`;
    };
    const updateModelValue = (value) => {
      isPickerActive.value = false;

      if (!value) {
        emit('update:modelValue', props.range ? ['', ''] : '');
        return;
      }

      if (props.range) {
        const rangeDateValue = typeof value === 'string' ? split(value, ' - ') : value;
        const isoDateFrom = format(parse(rangeDateValue[0], 'dd.LL.yyyy.', new Date()), 'yyyy-LL-dd');
        const isoDateTo = format(parse(rangeDateValue[1], 'dd.LL.yyyy.', new Date()), 'yyyy-LL-dd');
        emit('update:modelValue', [isoDateFrom, isoDateTo]);
        return;
      }

      const isoDate = format(parse(value, 'dd.LL.yyyy.', new Date()), 'yyyy-LL-dd');
      emit('update:modelValue', isoDate);
    };

    const datePickerActivated = () => {
      isPickerActive.value = true;
    };

    const datePickerHovered = (hovered) => {
      isClearIconActive.value = hovered && parsedModelValue.value;
    };

    onClickOutside(rootElement, () => {
      isPickerActive.value = false;
    });

    return {
      parsedModelValue,
      rootElement,
      datePicker,
      isPickerActive,
      isClearIconActive,
      updateModelValue,
      displayValue,
      datePickerActivated,
      datePickerHovered,
    };
  },
};
</script>

<style lang="scss">
.date-picker {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $white;
  padding: 6px 12px;
  border: $textInputBorder;
  border-radius: $textInputBorderRadius;
  width: 100%;
  height: 100%;
  transition: $textInputBoxShadowTransition;
  font-size: 14px;
  cursor: pointer;

  .date-picker-placeholder span {
    // color: #CDCDCD;
  }

  &::placeholder {
    color: $textInputPlaceholderColor;
    user-select: none;
  }

  &:focus {
    border: $textInputBorder;
    box-shadow: $textInputFocusBoxShadow;
    outline: none;
  }
  & span {
    color: $black;
  }

  &.active {
    background: #E5EBEB;
    box-shadow: $textInputFocusBoxShadow;
    outline: none;
  }

  &.error,
  &.error:hover {
    box-shadow: 0 0 0 1px $error500;
    outline: none;
  }

  .error-icon svg {
    background-color: $error500;
    fill: #fff;
    width: 14px;
    height: 14px;
    border-radius: 100%;
  }
}

.chevron-icon {
  stroke: $black;
  width: 14px;
  height: 14px;
}

.clear-icon {
  padding: 3px;
}

#litepie > label button {
  display: none;
}
#litepie {
  height: 100%;
}
</style>
