<template>
  <div class="filters-main--root">
    <div
      class="filter--single"
      v-for="filter in availableAndAppliedFilters"
      :key="filter.field"
    >
      <FilterMainItem
        v-if="filter && fieldModel(filter)"
        :filter="filter"
        :model="fieldModel(filter)"
      />
    </div>
  </div>
</template>
<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { find, map } from 'lodash';
import FilterMainItem from './FilterMainItem';

export default {
  components: {
    FilterMainItem,
  },
  setup() {
    const store = useStore();
    const availableFilters = computed(() => store.getters.eventListFilters);
    const appliedFilters = computed(() => store.getters.eventListAppliedFilters);
    const availableAndAppliedFilters = computed(() => map(availableFilters.value, (filter) => {
      const appliedFilter = find(appliedFilters.value, { field: filter.field });
      if (!appliedFilter) return filter;
      return {
        ...filter,
        value: appliedFilter.value,
      };
    }));
    const fieldModel = (filter) => find(store.getters.columns, { key: filter.field });
    return {
      availableAndAppliedFilters,
      fieldModel,
    };
  },
};
</script>

<style lang="scss">
.filters-main--root{
    display: flex;

    .filter--single {
      position: relative;
      z-index: 200;
    }
}
</style>
