<template>
  <div class="rows-per-page">
    <span class="rows-per-page-text">
      Showing
    </span>

    <div
      ref="dropdownElement"
      class="rows-per-page-dropdown"
      @click="toggleContentVisibility"
    >
      <div
        class="rows-per-page-dropdown-button"
        tabindex="0"
      >
        {{ selectedRowNumber }}

        <Icon :name="isContentVisible ? 'chevron-up' : 'chevron-down'" />
      </div>

      <div
        v-show="isContentVisible"
        class="rows-per-page-dropdown-content"
      >
        <div
          v-for="row in rowsOptions"
          :key="row"
          class="rows-per-page-dropdown-item"
          @click="updateSelectedRowNumber(row)"
        >
          {{ row }}
        </div>
      </div>
    </div>

    <span class="rows-per-page-text">
      results per page

      <span class="rows-per-page-text rows-per-page-text--muted">
        ({{ totalRowsCount }} in total)
      </span>
    </span>
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { onClickOutside } from '@vueuse/core';
import Icon from '@/components/common/Icon';

export default {
  components: {
    Icon,
  },
  setup() {
    const dropdownElement = ref(null);
    const isContentVisible = ref(false);
    const toggleContentVisibility = () => {
      isContentVisible.value = !isContentVisible.value;
    };
    onClickOutside(dropdownElement, () => {
      isContentVisible.value = false;
    });

    const store = useStore();
    const selectedRowNumber = computed(() => store.getters.rowsPerPage);
    const rowsOptions = ref([10, 20, 50, 100]);
    const updateSelectedRowNumber = (rowsPerPage) => {
      store.dispatch('setPaginationRowsPerPage', rowsPerPage);
    };
    const totalRowsCount = computed(() => store.getters.totalRowsCount);

    watch(
      () => store.getters.rowsPerPage,
      () => {
        store.dispatch('validatePaginationRowsPerPage');
      },
      { immediate: true },
    );

    return {
      dropdownElement,
      isContentVisible,
      toggleContentVisibility,
      selectedRowNumber,
      rowsOptions,
      updateSelectedRowNumber,
      totalRowsCount,
    };
  },
};
</script>

<style lang="scss">
.rows-per-page {
  display: flex;
  align-items: center;

  .rows-per-page-text {
    margin: $rowsPerPageTextMargin;

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }

    &.rows-per-page-text--muted {
      color: $rowsPerPageMutedTextColor;
    }
  }

  .rows-per-page-dropdown {
    position: relative;
  }

  .rows-per-page-dropdown-button {
    display: flex;
    align-items: center;
    background-color: $rowsPerPageDropdownButtonBackgroundColor;
    border: $rowsPerPageDropdownButtonBorder;
    border-radius: $rowsPerPageDropdownButtonBorderRadius;
    padding: $rowsPerPageDropdownButtonPadding;
    cursor: pointer;
    transition: $rowsPerPageDropdownButtonTransition;
    user-select: none;
    stroke: $gray800;

    .icon {
      margin-left: $rowsPerPageDropdownButtonIconMarginLeft;
    }

    &:hover,
    &:focus {
      background-color: $rowsPerPageDropdownButtonHoverBackgroundColor;
    }

    &:focus {
      outline: none;
      box-shadow: $rowsPerPageDropdownButtonFocusBoxShadow;
    }
  }

  .rows-per-page-dropdown-content {
    position: absolute;
    bottom: 100%;
    left: 0;
    background-color: $rowsPerPageDropdownContentBackgroundColor;
    box-shadow: $rowsPerPageDropdownContentBoxShadow;
    border-radius: $rowsPerPageDropdownContentBorderRadius;
    margin-bottom: $rowsPerPageDropdownContentMarginBottom;
    border: $rowsPerPageDropdownContentBorder;
    min-width: 100%;
    user-select: none;
    z-index: $dropdownMenuZIndex;
  }

  .rows-per-page-dropdown-item {
    display: flex;
    align-items: center;
    white-space: nowrap;
    background-color: $rowsPerPageDropdownItemBackgroundColor;
    border-bottom: $rowsPerPageDropdownItemBorderBottom;
    padding: $rowsPerpageDropdownItemPadding;
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: $rowsPerPageDropdownItemHoverBackgroundColor;
    }
  }
}
</style>
