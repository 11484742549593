<template>
  <div class="label">
    <Icon :name="icon" />
    <slot />
  </div>
</template>

<script>
import Icon from './Icon';

export default {
  components: {
    Icon,
  },
  props: {
    icon: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss">
.label {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;

  .icon {
    min-width: $labelIconSize;
    min-height: $labelIconSize;
    margin-right: $labelIconMarginRight;
  }
}
</style>
