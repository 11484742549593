<template>
  <Button
    v-if="loading && competitionId"
    icon-only
    loading
    disabled
  />
  <SplitButton
    v-else-if="competitionId"
    :variant="variant"
    :loading="processing"
    :actions="isSubscribed ? [] : subscriptionActions"
    icon="settings"
    @click="toggleSubscription"
    @handle-action="handleAction"
    @auxiliary-click="handleAction"
  >
    {{ label }}
  </SplitButton>
</template>

<script>
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import * as api from '@/services/api';
import { subscriptionTypes } from '@/services/helpers/subscriptions';
import Button from '@/components/common/Button';
import SplitButton from '@/components/common/SplitButton';

const subscriptionActionTypes = {
  CUSTOMIZED_SUBSCRIPTION: 'CUSTOMIZED_SUBSCRIPTION',
};

const subscriptionActions = [
  {
    id: subscriptionActionTypes.CUSTOMIZED_SUBSCRIPTION,
    label: 'Customized subscription',
  },
];

export default {
  components: {
    Button,
    SplitButton,
  },
  setup() {
    const store = useStore();
    const competitionId = computed(() => store.getters.sidebarCompetitionId);
    const isSubscribed = ref(false);
    const label = computed(() => (isSubscribed.value ? 'Unsubscribe' : 'Subscribe'));
    const variant = computed(() => (isSubscribed.value ? 'secondary' : 'primary'));

    const loading = ref(false);
    const load = () => {
      loading.value = true;
      api.isSubscribed(competitionId.value)
        .then((response) => {
          isSubscribed.value = response.data.subscribed;
        })
        .catch((error) => {
          isSubscribed.value = false; // allows user to retry
          console.log(error);
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const processing = ref(false);
    const subscribe = () => {
      processing.value = true;
      api.subscribe(competitionId.value)
        .then((response) => {
          isSubscribed.value = response.data.subscribed;
          store.dispatch('loadEvents');
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          processing.value = false;
        });
    };
    const unsubscribe = () => {
      processing.value = true;

      Promise.all([
        api.unsubscribe(competitionId.value),
        api.unsubscribeSameGameParlays(competitionId.value),
        api.unsubscribePlayerProps(competitionId.value),
      ]).then(() => {
        isSubscribed.value = false;
        store.dispatch('loadEvents');
      }).catch((error) => {
        console.log(error);
      }).finally(() => {
        processing.value = false;
      });
    };
    const toggleSubscription = () => {
      if (!isSubscribed.value) {
        subscribe();
        return;
      }

      unsubscribe();
    };

    const handleAction = () => {
      store.dispatch('openSubscriptionModal', {
        type: subscriptionTypes.COMPETITION,
        id: competitionId.value,
      });
    };

    watch(
      competitionId,
      (newValue) => {
        if (!newValue) {
          loading.value = false;
          processing.value = false;
          isSubscribed.value = false;
          return;
        }
        load();
      },
      { immediate: true },
    );

    return {
      subscriptionActions,
      competitionId,
      isSubscribed,
      label,
      variant,
      loading,
      processing,
      subscribe,
      unsubscribe,
      toggleSubscription,
      handleAction,
    };
  },
};
</script>
