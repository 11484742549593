<template>
  <div class="double-bar-chart">
    <div class="double-bar-chart__title">
      <component :is="valueA > valueB ? 'strong' : 'span'">
        {{ valueA }}
      </component>

      <span>
        {{ title }}
        ({{ valueA + valueB }})
      </span>

      <component :is="valueA < valueB ? 'strong' : 'span'">
        {{ valueB }}
      </component>
    </div>

    <div class="double-bar-chart__content">
      <div
        :class="[
          'double-bar-chart__bar',
          'double-bar-chart__bar--a',
          { 'double-bar-chart__bar--dominant': valueA > valueB },
        ]"
      >
        <span
          class="double-bar-chart__fill"
          :style="{
            'width': `${percentageA}%`,
          }"
        />
      </div>
      <div
        :class="[
          'double-bar-chart__bar',
          'double-bar-chart__bar--b',
          { 'double-bar-chart__bar--dominant': valueA < valueB },
        ]"
      >
        <span
          class="double-bar-chart__fill"
          :style="{
            'width': `${percentageB}%`,
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    firstValue: {
      type: Number,
      required: true,
      default: () => 0,
    },
    secondValue: {
      type: Number,
      required: true,
      default: () => 0,
    },
    reverse: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const valueA = computed(() => (props.reverse ? props.secondValue : props.firstValue));
    const valueB = computed(() => (props.reverse ? props.firstValue : props.secondValue));
    const percentageA = computed(() => 100 - (valueB.value / (valueA.value + valueB.value)) * 100 || 0);
    const percentageB = computed(() => 100 - (valueA.value / (valueB.value + valueA.value)) * 100 || 0);

    return {
      valueA,
      valueB,
      percentageA,
      percentageB,
    };
  },
};
</script>

<style lang="scss">
.double-bar-chart {
  display: flex;
  flex-direction: column;
  width: 100%;

  .double-bar-chart__title {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;
  }

  .double-bar-chart__content {
    display: flex;
    gap: 8px;
    height: 14px;
    margin-bottom: 16px;
  }

  .double-bar-chart__bar {
    background: #F0F0F0;
    height: 100%;
    width: 50%;
    border-radius: 8px;
    position: relative;
  }

  .double-bar-chart__fill {
    position: absolute;
    display: block;
    background: #668A8A;
    height: 100%;
    border-radius: 8px;
  }

  .double-bar-chart__bar.double-bar-chart__bar--a {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    .double-bar-chart__fill {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      right: 0;
    }
  }

  .double-bar-chart__bar.double-bar-chart__bar--b {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    .double-bar-chart__fill {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      left: 0;
    }
  }

  .double-bar-chart__bar.double-bar-chart__bar--dominant {
    .double-bar-chart__fill {
      background: #003C3C;
    }
  }
}
</style>
