<template>
  <ul
    v-show="pageNumberRange.length"
    class="pagination"
  >
    <li class="pagination-item">
      <div
        class="pagination-button pagination-button--navigation"
        @click="setPage(currentPage - 1)"
        :disabled="isFirstPage"
      >
        <Icon name="chevron-left" />
      </div>
    </li>

    <li
      v-show="!isFirstPageInRange"
      class="pagination-item"
    >
      <div
        class="pagination-button"
        @click="setPage(1)"
        :disabled="isFirstPage"
      >
        1
      </div>
    </li>

    <li
      v-show="showFirstEllipsis"
      class="pagination-item"
    >
      <span class="pagination-text">
        &hellip;
      </span>
    </li>

    <li
      class="pagination-item"
      v-for="pageNumber in pageNumberRange"
      :key="pageNumber"
    >
      <div
        :class="[
          'pagination-button',
          { 'pagination-button--toggled': currentPage === pageNumber },
        ]"
        @click="setPage(pageNumber)"
      >
        <span class="sr-only">Go to page</span>
        {{ pageNumber }}
      </div>
    </li>

    <li
      v-show="showLastEllipsis"
      class="pagination-item"
    >
      <span class="pagination-text">
        &hellip;
      </span>
    </li>

    <li
      v-show="!isLastPageInRange"
      class="pagination-item"
    >
      <div
        class="pagination-button"
        @click="setPage(lastPage)"
        :disabled="isLastPage"
      >
        {{ lastPage }}
      </div>
    </li>

    <li class="pagination-item">
      <div
        class="pagination-button pagination-button--navigation"
        @click="setPage(currentPage + 1)"
        :disabled="isLastPage"
      >
        <Icon name="chevron-right" />
      </div>
    </li>
  </ul>
</template>

<script>
import {
  min, max, range, includes,
} from 'lodash';
import { computed } from 'vue';
import { useStore } from 'vuex';
import Icon from '../Icon';

export default {
  components: {
    Icon,
  },
  setup() {
    const store = useStore();

    const currentPage = computed(() => store.getters.currentPage);
    const lastPage = computed(() => store.getters.lastPage);
    const pageNumberRange = computed(() => {
      if (currentPage.value === 1) {
        return range(1, min([lastPage.value, currentPage.value + 2]) + 1);
      }

      if (currentPage.value === lastPage.value) {
        return range(max([1, lastPage.value - 2]), lastPage.value + 1);
      }

      return range(currentPage.value - 1, currentPage.value + 1 + 1);
    });
    const isFirstPageInRange = computed(() => includes(pageNumberRange.value, 1));
    const showFirstEllipsis = computed(() => !isFirstPageInRange.value && lastPage.value !== 4 && currentPage.value - 2 > 1);
    const isLastPageInRange = computed(() => includes(pageNumberRange.value, lastPage.value));
    const showLastEllipsis = computed(() => !isLastPageInRange.value && lastPage.value !== 4 && currentPage.value + 2 < lastPage.value);

    const setPage = (page) => {
      if (page < 1 || page > lastPage.value || page === currentPage.value) {
        return;
      }

      store.dispatch('setPaginationPage', page);
    };

    return {
      currentPage,
      lastPage,
      pageNumberRange,

      setPage,
      setPreviousPage: () => {
        setPage(currentPage.value - 1);
      },
      setNextPage: () => {
        setPage(currentPage.value + 1);
      },

      isFirstPage: computed(() => currentPage.value === 1),
      isFirstPageInRange,
      showFirstEllipsis,
      isLastPage: computed(() => currentPage.value === lastPage.value),
      isLastPageInRange,
      showLastEllipsis,
    };
  },
};
</script>

<style lang="scss">
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  height: $paginationHeight;

  .pagination-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $paginationButtonBackgroundColor;
    border-radius: $paginationButtonBorderRadius;
    width: $paginationButtonWidth;
    height: $paginationButtonHeight;
    margin: $paginationButtonMargin;
    cursor: pointer;
    user-select: none;

    .icon {
      stroke: $paginationButtonIconStroke;
      width: $paginationButtonIconSize;
      height: $paginationButtonIconSize;
    }

    &:hover,
    &.pagination-button--navigation {
      border: $paginationNavigationButtonBorder;
    }

    &.pagination-button--toggled {
      background-color: $paginationToggledButtonBackgroundColor;
      border-color: $paginationToggledButtonBorderColor;
      color: $paginationToggledButtonTextColor;
    }

    &.pagination-button--navigation {
      width: $paginationNavigationButtonWidth;
      height: $paginationNavigationButtonHeight;

      &:hover {
        background-color: $paginationNavigationButtonHoverBackgroundColor;
      }

      &:focus {
        background-color: $paginationNavigationButtonFocusBackgroundColor;
      }
    }
  }

  .pagination-text {
    margin: $paginationTextMargin;
    user-select: none;
  }
}
</style>
